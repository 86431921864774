import Content, { HeaderBlock, HeaderSection } from 'components/Content'

export default function PrivacyPolicy() {
  return (
    <Content>
      <HeaderSection>
        <HeaderBlock preTitle='Privacy Policy' title='Our guidelines' />
      </HeaderSection>
      <div className='mt-6 prose prose-indigo prose-md text-gray-700 mx-auto'>
        <p>
          <em>Last updated: April 10, 2021</em>
        </p>
        <p>
          Brewbean is a privacy-first company. We make money from paying
          customers in our marketplace, along with first-party advertisement
          that does not use any tracking cookies. This means that you will
          neither get personalized ads nor will advertisers know any personal
          details about you. Fundamental to our ethos is being a decent online
          denizen. This means we thrive to never collect any extraneous data on
          our users beyond what we need to provide a high quality service.
        </p>
        <p>
          We only ask for personal information when we actually need it—like our
          payment form. We use fair and lawful means to collect it, and only
          with your knowledge and consent.
        </p>
        <p>
          The only third parties that we share data with are secure and we only
          share what’s required:
        </p>
        <ul>
          <li>
            SendGrid, for transactional and account-related emails. We only
            share email addresses with them. These emails are critical to the
            maintenance and continued use of your account, and we send these
            sparingly.
          </li>
          <li>
            Fathom Analytics, for anonymized usage data. This is a cookieless
            analytics tool that is compliant with GDPR, ePrivacy, PECR, CCPA,
            and COPPA. Analytics data is never sold. Only basic page view
            metrics such as unique visits and average time on site are shared
            with first-party advertisers.
          </li>
          <li>
            Stripe, for third-party secure payment processing. We do not store
            or collect any of your payment details.
          </li>
        </ul>
        <p>The legal text for our privacy policy:</p>
        <p>Effective date: February 18, 2021</p>
        <p>
          Brewbean ("us", "we", or "our") operates the https://brewbean.io
          website (hereinafter referred to as the "Service"). This page informs
          you of our policies regarding the collection, use and disclosure of
          personal data when you use our Service and the choices you have
          associated with that data.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, accessible from https://brewbean.io
        </p>
        <p>
          We want to process as little personal information as possible when you
          use our website. That's why we've chosen Fathom Analytics for our
          website analytics, which doesn't use cookies and complies with the
          GDPR, ePrivacy (including PECR), COPPA and CCPA. Using this
          privacy-friendly website analytics software, your IP address is only
          briefly processed, and we (running this website) have no way of
          identifying you. As per the CCPA, your personal information is
          de-identified. You can read more about this on{' '}
          <a href='https://usefathom.com/compliance'>
            Fathom Analytics' website
          </a>
          .
        </p>
        <p>
          The purpose of us using this software is to understand our website
          traffic in the most privacy-friendly way possible so that we can
          continually improve our website and business. The lawful basis as per
          the GDPR is "f); where our legitimate interests are to improve our
          website and business continually." As per the explanation, no personal
          data is stored over time.
        </p>

        <h2>Definitions</h2>
        <ul>
          <li>
            Service: service is the https://brewbean.io website operated by
            Brewbean.
          </li>
          <li>
            Personal Data: personal Data means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </li>
          <li>
            Usage Data: usage Data is data collected automatically either
            generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            Cookies: cookies are small files stored on your device (computer or
            mobile device).
          </li>
          <li>
            Data Controller: Data Controller means the natural or legal person
            who (either alone or jointly or in common with other persons)
            determines the purposes for which and the manner in which any
            personal information are, or are to be, processed. For the purpose
            of this Privacy Policy, we are a Data Controller of your Personal
            Data.
          </li>
          <li>
            Data Processors (or Service Providers): Data Processor (or Service
            Provider) means any natural or legal person who processes the data
            on behalf of the Data Controller. We may use the services of various
            Service Providers in order to process your data more effectively.
          </li>
          <li>
            Data Subject (or User): Data Subject is any living individual who is
            using our Service and is the subject of Personal Data.
          </li>
        </ul>
        <h2>Information Collection and Use</h2>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3>Personal Data</h3>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Non-personally identifiable personal data for usage</li>
        </ul>
        <h3>Usage Data</h3>
        <p>
          We do not collect information such as your computer's Internet
          Protocol address (e.g. IP address), browser version, unique device
          identifiers and other diagnostic data. All data collected is aggregate
          data without personal information &mdash; this is performed by Fathom
          Analytics.
        </p>
        <h3>Location Data</h3>
        <p>
          We do not use and store information about your location except for
          country of origin (we do not track IP address or anything more
          granular than country).
        </p>
        <h3>Tracking & Cookies Data</h3>
        <p>
          We do not use cookies and store data by using multiple, unrelated
          complex hashes to track visits to our site and pages on it. You can
          read about how our analytic tool collects data{' '}
          <a href='https://usefathom.com/blog/anonymization'>here</a>.
        </p>
        <h3>Use of Data</h3>
        <p>Brewbean uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
          <li>
            To provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
          <li>
            To provide first-party advertising partners about general
            performance of ads (e.g. average impressions of banner ads)
          </li>
        </ul>
        <p>
          Where we have your consent, we may use your Personal Data to contact
          you with newsletters, marketing or promotional materials and other
          information that may be of interest to you. You may opt out of
          receiving any, or all, of these communications from us by following
          the unsubscribe link or the instructions provided in any email we
          send.
        </p>
        <h3>
          Legal Basis for Processing Personal Data under the General Data
          Protection Regulation (GDPR)
        </h3>
        <p>
          If you are from the European Economic Area (EEA), Brewbean's legal
          basis for collecting and using the personal information described in
          this Privacy Policy depends on the Personal Data we collect and the
          specific context in which we collect it.
        </p>
        <p>Brewbean may process your Personal Data because:</p>
        <ul>
          <li>We need to perform a contract with you</li>
          <li>You have given us permission to do so</li>
          <li>
            The processing is in our legitimate interests and it is not
            overridden by your rights
          </li>
          <li>For payment processing purposes</li>
          <li>To comply with the law</li>
        </ul>
        <h3>Retention of Data</h3>
        <p>
          Brewbean will retain your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes and
          enforce our legal agreements and policies.
        </p>
        <p>
          Brewbean will also retain Usage Data for internal analysis purposes.
          Usage Data is generally retained for a shorter period of time, except
          when this data is used to strengthen the security or to improve the
          functionality of our Service, or we are legally obligated to retain
          this data for longer periods.
        </p>
        <h3>Transfer of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
        <p>
          If you are located outside North America and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to North America and process it there.
        </p>
        <p>
          Brewbean will take all the steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h3>Disclosure for Law Enforcement</h3>
        <p>
          Under certain circumstances, Brewbean may be required to disclose your
          Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h3>Legal Requirements</h3>
        <p>
          Brewbean may disclose your Personal Data in the good faith belief that
          such action is necessary to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Brewbean</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <h3>Security of Data</h3>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h3>
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </h3>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. Brewbean aims to take reasonable steps
          to allow you to correct, amend, delete or limit the use of your
          Personal Data.
        </p>
        <p>
          If you wish to be informed about what Personal Data we hold about you
          and if you want it to be removed from our systems, please contact us.
        </p>
        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ul>
          <li>
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Personal Data directly within your account settings
            section. If you are unable to perform these actions yourself, please
            contact us to assist you.
          </li>
          <li>
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
          </li>
          <li>
            The right to object. You have the right to object to our processing
            of your Personal Data.
          </li>
          <li>
            The right of restriction. You have the right to request that we
            restrict the processing of your personal information.
          </li>
          <li>
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
          </li>
          <li>
            The right to withdraw consent. You also have the right to withdraw
            your consent at any time where Brewbean relied on your consent to
            process your personal information.
          </li>
        </ul>
        <p>
          Please note that we may ask you to verify your identity before
          responding to such requests.
        </p>

        <p>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third-party companies and individuals to facilitate our
          Service ("Service Providers"), provide the Service on our behalf,
          perform Service-related services or assist us in analyzing how our
          Service is used.
        </p>

        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>

        <h3>Links to Other Sites</h3>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third-party link, you will be directed to that
          third-party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
        <h3>Children's Privacy</h3>
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers.
        </p>
        <h3>Changes to This Privacy Policy</h3>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h3>Contact Us</h3>
        <p>
          Get in touch with us at{' '}
          <a href='mailto:info@brewbean.io'>info@brewbean.io</a>
        </p>
      </div>
    </Content>
  )
}
