export const keys = {
  bean_aggregate: () => null,
  bean_aggregate_fields: () => null,
  bean_review_aggregate: () => null,
  bean_review_aggregate_fields: () => null,
  bean_review_avg_fields: () => null,
  recipe_review_aggregate: () => null,
  recipe_review_aggregate_fields: () => null,
  recipe_review_avg_fields: () => null,
  recipe_aggregate: () => null,
  recipe_aggregate_fields: () => null,
  brew_log_aggregate: () => null,
  brew_log_aggregate_fields: () => null,
  cafe_review_aggregate: () => null,
  cafe_review_aggregate_fields: () => null,
}
